import React from "react"
import {Row, Col, Container} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import one from "../images/one.jpg";
import two from "../images/two.jpg";
import three from "../images/three.jpg";
import four from "../images/four.jpg";
import five from "../images/five.jpg";
import six from "../images/six.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;


const IndexPage = () => (
    <Layout pageInfo={{pageName: "index"}}>
        <SEO title="Home" keywords={[`gems`, `scratch patch`, `Sage Centre`, 'Childrens activity']}/>
        <Container fluid className="text-center">
            <Row noGutters className='about-section'>
                <Col className='d-flex justify-content-center'>
                    <div>
                        <h1 className='header-font'>Crazy about gems</h1>
                        <p>
                            If you are looking for an activity for your kids that is both fun and educational
                            then the Sage Scratch Patch is the perfect place. Once you have purchased a jar,
                            they can spend as long as they like filling it up with all their favourite gemstones.
                            We have an enthusiastic and happy staff member always manning the Scratch Patch so that
                            parents can go enjoy a coffee at the Sage Cafe or browse the shops while the kids
                            enjoy the ultimate gem hunt. The Scratch Patch is in the children’s play area at
                            the Sage Centre. It is an outdoor activity (mostly shaded) so unfortunately we
                            are closed if it is raining.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid id='info' className='info-section'>
            <Row noGutters>
                <Col className='pt-5'>
                    <h1 className='text-center header'>Info</h1>
                    <hr className='before'></hr>
                    <hr className='after'></hr>
                </Col>
            </Row>
            <Row noGutters className='px-5'>
                <Col className='mx-lg-auto mx-md-0 pb-5 d-flex flex-column align-items-center' md={6} lg={3}>
                    <div className='pb-4'>
                        <h2>Opening hours:</h2>
                    </div>
                    <div className='px-5'>
                        <p>Friday: 9:00 am - 4:00 pm</p>
                        <p>Saturday: 9:00 am - 4:00 pm</p>
                        <p>Sunday: 9:00 am - 4:00 pm</p>
                    </div>
                </Col>
                <Col className='mx-lg-auto mx-md-0 pb-5 d-flex flex-column align-items-center' md={6} lg={3}>
                    <div className='pb-4'>
                        <h2>Prices:</h2>
                    </div>
                    <div className='px-5'>
                        <p>Big Jar: R 95.00</p>
                        <p>Small Jar: R 65.00</p>
                    </div>
                </Col>
                <Col className='mx-lg-auto mx-md-0 pb-5 d-flex flex-column align-items-center' md={6} lg={3}>
                    <div className='pb-4'>
                        <h2>Contact:</h2>
                    </div>
                    <div className='px-5'>
                        Address: <a className='pb-3 link' href='https://goo.gl/maps/t81JjGSQZNUBqgiW9'>1 Old Fort Rd,
                        Salt
                        Rock, Durban, 4391</a>
                        <p className='pt-3'>Call: 064 817 2980</p>
                        <p>Email: info@sagescratchpatch.co.za</p>
                        <a href='https://www.instagram.com/sagescratchpatch/' target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" style={{color: '#B25F89'}}/>
                        </a>
                        <a href='https://www.facebook.com/Sage-Scratch-Patch-115339336531673/' className='px-4'
                           target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} size="2x" style={{color: '#B25F89'}}/>
                        </a>
                    </div>
                </Col>
                <Col className='mx-lg-auto mx-md-0 pb-5 d-flex flex-column align-items-center' md={6} lg={3}>
                    <div className='pb-4'>
                        <h2>Parties:</h2>
                    </div>
                    <div className='px-5'>
                        <p>
                            If you are wanting to hire out the venue for a Scratch Patch birthday party we have packages
                            to
                            fit your needs.
                        </p>
                        <a href="mailto:info@sagescratchpatch.co.za?subject=Birthday Party" className="btn btn-outline-white py-3 px-5">Contact Us</a>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container  id='gallery' className='gallery-section'>
            <Row noGutters>
                <Col>
                    <h1 className='text-center header'>Gallery</h1>
                    <hr className='before'></hr>
                    <hr className='after'></hr>
                </Col>
            </Row>
            <Row  className='px-5 justify-content-center pb-3'>
                <Col className='mb-4' md={6} lg={4}>
                    <img alt='gallery' style={{width: '100%', borderRadius: '4px', margin: 'auto', display: 'block'}} src={one}/>
                </Col>
                <Col className='mb-4' md={6} lg={4}>
                    <img alt='gallery' style={{width: '100%', borderRadius: '4px', margin: 'auto', display: 'block'}} src={two}/>
                </Col>
                <Col className='mb-4' md={6} lg={4}>
                    <img alt='gallery' style={{width: '100%', borderRadius: '4px', margin: 'auto', display: 'block'}} src={three}/>
                </Col>
                <Col className='mb-4' md={6} lg={4}>
                    <img alt='gallery' style={{width: '100%', borderRadius: '4px', margin: 'auto', display: 'block'}} src={four}/>
                </Col>
                <Col className='mb-4' md={6} lg={4}>
                    <img alt='gallery' style={{width: '100%', borderRadius: '4px', margin: 'auto', display: 'block'}} src={five}/>
                </Col>
                <Col className='mb-4' md={6} lg={4}>
                    <img alt='gallery' style={{width: '100%', borderRadius: '4px', margin: 'auto', display: 'block'}} src={six}/>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default IndexPage
